import * as React from 'react';

import {
  Text,
  BlogImage,
  BlogInfographics,
  BlogLiterature,
} from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import infographic from '../../../images/educational-corner/blog9/infografika.png';
import negativeImpact from '../../../images/educational-corner/blog9/Negativan-utjecaj-na-pravilan-izgovor.png';
import positiveImpact from '../../../images/educational-corner/blog9/Pozitivan-utjecaj-na-pravilan-izgovor.png';

import './index.css';

const EncouragingCorrectPronunciation = () => {
  return (
    <PageNew
      title="KOKOLINGO - Poticanje pravilnog izgovora riječi i glasova"
      description="Dječji govor mijenja se intenzivno od rođenja pa sve do pete godine. 
      Djetetove su prve riječi kraće dvosložne riječi koje dijete svakodnevno čuje, 
      kao što su mama, tata, baka, deda, medo, beba i slično. 
      S vremenom će se djetetov rječnik širiti te početi 
      upotrebljavati dulje i složenije riječi."
      headline="Poticanje pravilnog izgovora riječi i glasova"
    >
      <Text as="p">
        Dječji govor mijenja se intenzivno od rođenja pa sve do pete godine.
        Djetetove su prve riječi kraće dvosložne riječi koje dijete svakodnevno
        čuje, kao što su mama, tata, baka, deda, medo, beba i slično. S vremenom
        će se djetetov rječnik širiti te početi upotrebljavati dulje i složenije
        riječi. Dulje će riječi dijete pojednostaviti kako bi ih moglo lakše
        izgovoriti (primjerice lopta = ota, banana = nana).
        <b>
          {' '}
          Upotreba pojednostavljenih riječi i nepravilan izgovor glasova u ranoj
          dobi nije pogreška, nego jedno od obilježja razvoja govora.{' '}
        </b>
        Iako u ranoj dobi možemo tolerirati pogreške u izgovoru, to ne znači da
        nećemo reagirati na njih. Poželjno je već u ranoj dobi, kada dijete tek
        počinje oblikovati prve rečenice, poticati pravilan izgovor riječi i
        glasova.
      </Text>
      <BlogImage
        imgSrc={negativeImpact}
        imgAlt="Negativan utjecaj na pravilan izgovor rijeci"
        imgTitle="Negativan utjecaj na pravilan izgovor rijeci"
      />
      <Text as="h2">Što negativno utječe na pravilan izgovor?</Text>
      <Text as="p">
        <ul>
          <li>
            <b>Učestalo tepanje djetetu</b>
          </li>
        </ul>
        <b>Dijete uči govor prema modelu, </b>
        odnosno slušajući osobe iz svoje okoline. Ako djetetu pružamo pravilan
        govorni model, dijete će izgovor riječi naučiti prema tom modelu. Isto
        tako, ako dijete od osoba iz okoline čuje nepravilan izgovor riječi, i
        samo će početi tako govoriti. Nepravilan izgovor riječi često je
        prisutan pri tepanju. Tijekom tepanja riječi se iskrivljuju:
        <ul>
          <li>
            zamjenama glasova: <i>cunce</i> umjesto <i>sunce</i>, <i>mis</i>{' '}
            umjesto <i>miš</i>, <i>nona</i> umjesto <i>noga</i>
          </li>
          <li>
            dodavanjem dijelova riječi: <i>ličeko</i> umjesto <i>lice</i>,{' '}
            <i>mačkice</i> umjesto <i>mačke</i>
          </li>
          <li>
            izostavljanjem dijelova riječi: <i>nana</i> umjesto <i>banana</i>,{' '}
            <i>tirić</i> umjesto <i>leptir</i>.
          </li>
        </ul>
      </Text>
      <Text as="p">
        Ako dijete čuje učestalo takve oblike riječi, usvojit će takve oblike i
        upotrebljavati ih u vlastitom govoru. S vremenom, upotreba prevelikog
        broja iskrivljenih oblika riječi utječe na to kako šira okolina razumije
        dijete (npr. druga djeca, odgojitelji ili neki članovi obitelji). Osim
        što pretjerana uporaba riječi prisutnih u tepanju utječe na izgovor,
        utječe i na sintaktičku složenost rečenica, što je vidljivo u korištenju
        pogrešnih oblika u množini imenica (tirići umjesto leptiri, misici ili
        misi umjesto miševi).
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>Dugotrajno korištenje dudom</b>
          </li>
        </ul>
        Veliki broj male djece počinje se koristiti dudom nedugo nakon rođenja.
        Iako upotreba dude varalice umiruje djecu,
        <b>
          {' '}
          dugotrajno korištenje negativno utječe na građu i pokretljivost
          govornih organa.{' '}
        </b>
        Vrlo često djeca koja se dugo koriste dudom, bočicom ili dugo sišu palac
        imaju otvoren zagriz. Otvoren zagriz vrsta je nepravilnog zagriza u
        kojem ne dolazi do preklapanja gornjih i donjih zubi. Budući da je
        adekvatan kontakt gornjih i donjih zubi potreban pri izgovoru većeg
        broja glasova, kao posljedica nepravilnog zagriza javljaju se
        artikulacijske teškoće. Najčešće se radi o interdentalnom sigmatizmu,
        odnosno nepravilnom izgovoru glasova /S/, /Z/, /C/, /Š/, /Ž/, /Č/,
        /Ć/,/DŽ/ i /Đ/. Kod interdentalnog sigmatizma jezik se nalazi između
        gornjih i donji zubi, odnosno “bježi” izvan usne šupljine, dok se kod
        pravilnog izgovora nalazi iza zubi. Učestalo i dugotrajno korištenje
        dudom utječe na položaj jezika čak i kad duda nije u ustima. Tako je
        jezik često spušten, postaje pasivan i manje pokretljiv. Dobra
        pokretljivost jezika, uz već spomenute glasove, potrebna je za izgovor
        glasova /L/, /R/ i /LJ/, stoga djeca koja se dugotrajno koriste dudom
        mogu imati teškoća s izgovorom tih glasova. Zbog toga je potrebno
        prevenirati nastanak nepravilnog zagriza, odnosno maknuti dudu iz
        uporabe.
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>Pretjerano ispravljanje djetetova govora</b>
          </li>
        </ul>
        Čestim ispravljanjem djetetova govora dajemo djetetu povratnu
        informaciju da njegov govor nije dobar.
        <b>
          {' '}
          Dijete ne govori pogrešno namjerno, nego zato što nije ovladalo
          slušnim i/ili motoričkim vještinama za izgovor pojedinih glasova i
          riječi.{' '}
        </b>
        Vrlo rano djeca postaju svjesna svojih pogrešaka u govoru i počinju ih
        sama ispravljati ako to mogu. Čestim ispravljanjem šaljemo djetetu
        poruku da nismo zadovoljni njihovim govorom, što negativno utječe na
        djetetov stav prema vlastitu govoru te posljedično smanjuje pokušaje
        iniciranja komunikacije, sudjelovanja u razgovoru i prepričavanja
        doživljaja.
      </Text>
      <BlogImage
        imgSrc={positiveImpact}
        imgAlt="Pozitivan utjecaj na pravilan izgovor rijeci"
        imgTitle="Pozitivan utjecaj na pravilan izgovor rijeci"
      />
      <Text as="h2">Što pozitivno utječe na pravilan izgovor?</Text>
      <Text as="p">
        <ul>
          <li>
            <b>Imitiranje zvukova iz djetetove okoline</b>
          </li>
        </ul>
        Imitiranje zvukova iz okoline ponajprije usmjerava djetetovu pozornost
        na zvukove te time pomaže u razvoju slušne pažnje. Osim toga, imitiranje
        zvukova pomaže djetetu da poveže zvuk s pojmom, što pospješuje
        razumijevanje, upamćivanje i upotrebu tog pojma. Imitiranje zvukova
        ujedno je dobra priprema za izgovor pojedinih glasova i slogovnih
        struktura. Možete oponašati:
        <ul>
          <li>
            zvukove životinja: vau-vau, mijau, pčela – <i>zzz</i>, zmija –{' '}
            <i>ssss</i>, kokoš – <i>ko-ko</i>
          </li>
          <li>
            zvukove predmeta iz okoline: vjetar – <i>ŠŠŠŠ</i>, sat –{' '}
            <i>tik-tak</i>, zvono – <i>cin-cin</i>, auto – <i>bm-bm</i>, alati –{' '}
            <i>zzz</i>
          </li>
        </ul>
        <b>
          Dijete će rado ponavljati za vama zato što je imitiranje zvukova iz
          okoline oblik igre i zato što je takve oblike riječi djetetu
          jednostavno izgovoriti.{' '}
        </b>
        Kod djece koja još ne koriste rečenice imitiranje zvukova ujedno može
        biti poticaj za početna spajanja riječi, odnosno za tvorbu prvih
        rečenica gdje se dijete umjesto glagola koristi onomatopejom zvuka iz
        okoline (primjerice auto bm-bm, pas vau-vau).
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>
              Pjevanje pjesmica, recitiranje brojalica, igranje igara u
              stihovima
            </b>
          </li>
        </ul>
        Pjesmice i brojalice djeci su vrlo atraktivne jer su varijabilne po
        melodiji, jednostavnog jezika i sadržavaju mnogo ponavljanja. Učenje i
        ponavljanje pjesmica i brojalica potiče kod djece razvoj slušne pažnje,
        odnosno povećava osjećaj za ritam i tempo u govoru, osjećaj za
        intonaciju te osjetljivost na manje dijelove govora kao što su slogovi
        ili glasovi.
        <b>
          {' '}
          Budući da su u pjesmicama i brojalicama prisutna brojna ponavljanja
          pojmova, one također mogu pozitivno utjecati na usvajanje novih
          pojmova.{' '}
        </b>
        Poželjno je upariti pjesmice s pokretima tijela kao što su pljeskanje,
        lupkanje ili drugi pokreti ruku vezani uz pjesmicu zbog toga što
        uparivanje pokreta i zvuka pozitivno utječe na pamćenje i na usvajanje
        novih pojmova.
      </Text>
      <Text as="p">
        <ul>
          <li>
            <b>Pravilno reagiranje na djetetove pogreške u izgovoru</b>
          </li>
        </ul>
        Djetetove su prve riječi i rečenice pojednostavljene, što je očekivano s
        obzirom na to da nema još dobro razvijenu slušnu percepciju i oralnu
        motoriku. Dječja pojednostavljivanja vidljiva su kroz izostavljanje
        glasova (ruka = uka), zamjene glasova (kiša = kisa) ili izostavljanja
        slogova (banana = nana). Kada dijete čini takve pogreške, nije dobro
        direktno ispravljati njegov govor. Umjesto toga trebamo djetetu biti
        dobar govorni primjer, odnosno
        <b>
          {' '}
          ponoviti riječ za njime sporije, s naglašenim onim dijelom riječi koje
          dijete ne može izgovoriti.
        </b>
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>
            <i>PRIMJER</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Vidi, ato! Bm, bm!</i>
              </li>
              <li>
                RODITELJ: <i>Da, to je veliki auto! </i> (naglasiti dio riječi
                AU, koje dijete nije izgovorilo)
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        Ako je dijete nepravilno izgovorilo neku dužu riječ, osim što tu riječ
        možemo ponoviti sporije, možemo je izgovoriti podijeljenu na slogove, uz
        produljenje trajanja vokala.
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>
            <i>PRIMJER</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Daj nanu!</i>
              </li>
              <li>
                RODITELJ: <i>Evo ti baa-na-na. </i> (naglasiti slog BA)
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        Ponekad nije potrebno razdvajanje riječi na sve slogove, nego je
        dovoljno odvojiti i naglasiti samo onaj dio riječi koji dijete nije
        izgovorilo (primjerice BA - nana).
      </Text>
      <Text as="p">
        Ako je dijete izostavilo posljednji slog, osim prethodnih tehnika možemo
        se koristiti i tehnikom izgovora unatrag, odnosno ponovimo prvo dio
        riječi koji je bio težak za izgovor, a zatim cijelu riječ:
      </Text>
      <Text as="p">
        <ul className="listBullet">
          <li>
            <i>PRIMJER</i>
          </li>
          <li>
            <ul className="listBullet">
              <li>
                DIJETE: <i>Vozi koli!</i>
              </li>
              <li>
                RODITELJ: <i>Bravo, voziš koliCA (naglasiti Ca)</i> ili{' '}
                <i>CA! Koli-ca!</i>
              </li>
            </ul>
          </li>
        </ul>
      </Text>
      <Text as="p">
        Važno je da pritom taj slog ne izgovorimo ozbiljno, odnosno tako da
        djetetu dajemo do znanja da je krivo izgovorilo. Umjesto toga taj ćemo
        slog (u ovom slučaju <i>CA</i>) izgovoriti razigrano, pjevno, kako bismo
        pridobili djetetov interes i pažnju te kako bismo poticali pozitivan
        stav prema govoru.
      </Text>
      <Text as="h2">SAVJET ZA KRAJ</Text>
      <Text as="p">
        <b>
          Slušanje i gledanje pravilnoga govornog modela omogućuje djetetu da
          usvoji pravilan izgovor riječi i glasova.{' '}
        </b>
        Roditelji, vi s djetetom provodite najviše vremena, stoga je važno da
        budete dobar govorni model. Nastojite što pravilnije izgovarati riječi.
        Reagirajte na djetetove pogreške, tako da im naglasite pravilan oblik
        riječi te izbjegavajte ponašanja koja negativno utječu na razvoj govora.
      </Text>
      <Text as="p">Maja Grden, mag. logoped.</Text>
      <BlogLiterature>
        <ul>
          <li>
            Fox, A. V., Dodd B., Howard, D. (2002). Risk factors for speech
            disorders in children.{' '}
            <i>
              International journal of language &amp; communication disorders
            </i>
            , 37,117–131.
          </li>
          <li>
            Rade, R. (2003).{' '}
            <i>
              Poticanje ranog govorno-jezičnog razvoja : namijenjeno roditeljima
              djece usporena govorno-jezičnog razvoja.
            </i>{' '}
            Zagreb: FoMa.
          </li>
        </ul>
      </BlogLiterature>
      <BlogInfographics infographicSrc={infographic} />
      <SectionBlog />
    </PageNew>
  );
};

export default EncouragingCorrectPronunciation;
